import styled from 'styled-components';

export const Container = styled.div``;

export const FeedCardList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const ListItem = styled.li`
  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: unset;
  }
`;
