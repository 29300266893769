import { updateSignUpMetadata } from '@/infra/api/auth/endpoints';
import { getLoggedUserHelper } from '@/infra/api/auth/helpers/getLoggedUser';
import { setUserIsProbablyLoggedCookie } from '@/infra/auth/tokenManagement';
import { addContextOnboardingVideoAlertLocalStorage } from '@/infra/storage/contextOnboardingVideoAlert';
import {
  addTooltipOnboardingAlertHomeLocalStorage,
  removeOnboardingStageFromLocalStorage,
} from '@/infra/storage/onboardingManagement';
import { SignupComponentSource } from '@/presentation/components/Header';
import { onboardingModalStyle as newOnboardingModalStyle } from '@/presentation/hooks/useNewOnboardingModal/style';
import { isOnboardingUserTypeEnabled } from '@/presentation/services/featureFlags';
import { getMetadataClient } from '@/presentation/services/runtime';
import { useModal } from '@pelando/components';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';

import { SocialAuthResponseData } from '../..';
import { AuthKind, AuthProvider } from '../../types';

const NewOnboardingModal = dynamic(
  () =>
    import('@/presentation/components/NewOnboarding/OnboardingModal').then(
      (mod) => mod.OnboardingModal
    ),
  { ssr: false }
);

type SignupMetadataVariables = {
  signupMethod?: AuthProvider;
  componentSource: SignupComponentSource;
};

export const useHandleAuthGoogleOnTap = () => {
  const { showModal, closeModal } = useModal();
  const { pathname } = useRouter();

  const updateMetadata = async ({
    componentSource,
    signupMethod,
  }: SignupMetadataVariables) => {
    const input = JSON.stringify({
      signup_method: signupMethod,
      component_source: componentSource,
      signup_path: pathname,
      client: getMetadataClient(),
    });

    await updateSignUpMetadata.requestAsPromise({ input });
  };

  const handleAuth = async (authResult: SocialAuthResponseData) => {
    const { authActionResult } = authResult;

    setUserIsProbablyLoggedCookie(true);
    getLoggedUserHelper();

    if (authActionResult === AuthKind.SIGNUP) {
      updateMetadata({
        signupMethod: AuthProvider.GOOGLE,
        componentSource: SignupComponentSource.UnloggedDefault,
      });

      if (isOnboardingUserTypeEnabled()) {
        showModal(
          <NewOnboardingModal
            onFinished={() => {
              removeOnboardingStageFromLocalStorage();
              closeModal();
              addContextOnboardingVideoAlertLocalStorage();
              addTooltipOnboardingAlertHomeLocalStorage(true);
            }}
          />,
          {
            style: newOnboardingModalStyle,
            modalBoxBorderRadius: 0,
            bannerImage: false,
            preventClose: true,
          }
        );
      }
    } else {
      closeModal();
    }
  };

  return { handleAuth };
};
